@import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html,
body,
#root {
  height: 100%;
}

.App {
}

.container {
  flex-direction: column;
}

.custom-container,
.custom-container-fluid,
.custom-container-xxl,
.custom-container-xl,
.custom-container-lg,
.custom-container-md,
.custom-container-sm {
  --bs-gutter-x: 1.5rem;
  padding-top: 65px;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .custom-container-sm,
  .custom-container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .custom-container-md,
  .custom-container-sm,
  .custom-container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .custom-container-lg,
  .custom-container-md,
  .custom-container-sm,
  .custom-container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .custom-container-xl,
  .custom-container-lg,
  .custom-container-md,
  .custom-container-sm,
  .custom-container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1320px;
  }
}

a:hover {
  border: none;
}

a:link {
  border: none;
}

a:visited {
  border: none;
}
